* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: unset;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

html {
  scroll-behavior: smooth;
}
html body {
  margin: 0;
  font-family: "M PLUS Rounded 1c", sans-serif;
}
html body .App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
html body .App .app-bar {
  flex: none;
  background-color: #040252;
  color: white;
  padding: 1rem;
}
html body .App .wrapper {
  margin: 0 1rem;
  flex-grow: 1;
}
html body .App .wrapper .container {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
html body .App .wrapper .container .column2 aside .tools-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
html body .App .wrapper .container .column2 aside .tools-list li {
  display: block;
  margin: 0.5rem;
  border: 2px solid #040252;
  background-color: #edf3ff;
}
html body .App .wrapper .container .column2 aside .tools-list li a .tools-list-item__inner {
  width: 150px;
  aspect-ratio: 1;
  position: relative;
}
html body .App .wrapper .container .column2 aside .tools-list li a .tools-list-item__inner .centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
html body .App .wrapper .container .column2 aside .tools-list li a .tools-list-item__inner .centered .tool-title {
  font-size: large;
}
html body .App .bottom-bar {
  background-color: #040252;
  color: white;
  text-align: right;
  padding: 1rem;
}

@media screen and (1000px <= width < 1200px) {
  body {
    background-color: #eee;
  }
  body .container {
    display: flex;
  }
  body .container .column1 {
    flex: 1;
    border-radius: 6px;
    background-color: white;
    padding: 1rem;
  }
  body .container .column2 {
    flex: none;
    width: 400px;
    margin-left: 1rem;
    border-radius: 6px;
    background-color: white;
    padding: 1rem;
  }
}
@media screen and (1200px <= width) {
  body {
    background-color: #eee;
  }
  body .container {
    display: flex;
  }
  body .container .column1 {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 1200px;
    border-radius: 6px;
    background-color: white;
    padding: 1rem;
  }
  body .container .column2 {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 400px;
    margin-left: 1rem;
    border-radius: 6px;
    background-color: white;
    padding: 1rem;
  }
}
h1 {
  text-align: center;
}

h2.center {
  text-align: center;
  position: relative;
  padding: 0;
}
h2.center::before {
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  width: 100%;
  height: 2px;
  content: "";
  background: #040252;
}
h2.center span {
  position: relative;
  padding: 0 1em;
  background: #fff;
}

.todo-app ul {
  margin-bottom: 1rem;
}
.todo-app ul .todo-item-container {
  padding: 1rem 0;
  border-bottom: 1px solid black;
}
.todo-app .todo-name-input-container {
  margin: 1rem 0;
}
.todo-app .action-buttons {
  display: flex;
  justify-content: space-between;
}

.pw-generator-app .control-group-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pw-generator-app .control-group-item .count-input {
  padding: 0.3rem;
}
.pw-generator-app .control-group-item.password-length {
  flex-direction: column;
  align-items: flex-start;
}
.pw-generator-app .control-group-item.password-length .password-length-title {
  padding: 0.5rem 0;
}
.pw-generator-app .control-group-item.password-length .slider-container {
  width: 100%;
  display: flex;
  align-items: center;
}
.pw-generator-app .generate-button-container {
  text-align: center;
}
.pw-generator-app ul {
  margin-bottom: 1rem;
}
.pw-generator-app ul .password-list-item-container {
  padding: 1rem 0;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pw-generator-app ul .password-list-item-container span {
  word-break: break-all;
  margin-right: 1rem;
}
.pw-generator-app ul .password-list-item-container button {
  flex-shrink: 0;
}

@media screen and (1200px <= width) {
  .pw-generator-app .control-group-item.password-length {
    flex-direction: row;
    align-items: center;
  }
  .pw-generator-app .control-group-item.password-length .slider-container {
    max-width: 50%;
  }
}
.links-app .link-list .link-container {
  margin-bottom: 1rem;
  border-radius: 8px;
  padding: 0 1rem;
  background: linear-gradient(145deg, #ffffff, #e6e6e6);
  box-shadow: 15px 15px 30px #cfcfcf, -15px -15px 30px #ffffff;
}
.links-app .link-list .link-container h3 {
  padding: 1em 0;
  margin: 0;
}
.links-app .link-list .link-container h3:hover {
  text-decoration: underline;
}
.links-app .link-list .link-container p {
  padding-bottom: 1rem;
  margin: 0;
}
.links-app .add-link-form .field-container {
  margin: 1rem 0;
}
.links-app .add-link-form .add-button-container {
  text-align: center;
}

.clock {
  width: min(500px, 100%);
  aspect-ratio: 1;
  background: #edf3ff;
  position: relative;
  border-radius: 50%;
  margin: auto;
}
.clock .h-hand,
.clock .m-hand,
.clock .s-hand {
  position: absolute;
  transform-origin: bottom;
  border-radius: 6px;
}
.clock .h-hand {
  width: 2.4%;
  height: 32%;
  background: #999;
  top: 18%;
  left: 48.8%;
  rotate: var(--degHour);
}
.clock .m-hand {
  width: 1.6%;
  height: 44%;
  background: #999;
  top: 6%;
  left: 49.2%;
  rotate: var(--degMin);
}
.clock .s-hand {
  width: 0.8%;
  height: 40%;
  background: #040252;
  top: 10%;
  left: 49.6%;
  rotate: var(--degSec);
}
.clock .center {
  width: 4%;
  aspect-ratio: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: #040252;
}
.clock .number-hand {
  height: 48%;
  width: 60px;
  position: absolute;
  font-size: 40px;
  color: black;
  transform-origin: bottom;
  bottom: 50%;
  left: calc(50% - 30px);
  rotate: var(--deg);
}
.clock .number-hand .number {
  text-align: center;
  rotate: calc(-1 * var(--deg));
}

.piano-app .keyboard {
  display: flex;
  border: 1px solid black;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.piano-app .keyboard .octave {
  display: flex;
  width: 100%;
  aspect-ratio: 1.6;
  max-height: 80vh;
  position: relative;
}
.piano-app .keyboard .octave .white-keys {
  display: flex;
  width: 100%;
}
.piano-app .keyboard .octave .white-keys .white-key {
  flex-grow: 1;
  height: 100%;
  background-color: white;
  border: 1px solid black;
  box-sizing: border-box;
}
.piano-app .keyboard .octave .white-keys .white-key:active {
  background-color: #ddd;
}
.piano-app .keyboard .octave .black-keys {
  display: flex;
  width: 100%;
  height: 66.6666666667%;
  position: absolute;
  top: 0;
  left: 0;
}
.piano-app .keyboard .octave .black-keys .black-keys-half {
  display: flex;
  justify-content: space-evenly;
}
.piano-app .keyboard .octave .black-keys .black-keys-half.first-half {
  width: 42.8571428571%;
}
.piano-app .keyboard .octave .black-keys .black-keys-half.first-half .black-key {
  width: 20.7407407407%;
}
.piano-app .keyboard .octave .black-keys .black-keys-half.second-half {
  width: 57.1428571429%;
}
.piano-app .keyboard .octave .black-keys .black-keys-half.second-half .black-key {
  width: 15.5555555556%;
}
.piano-app .keyboard .octave .black-keys .black-keys-half .black-key {
  background-color: black;
  color: white;
}
.piano-app .keyboard .octave .black-keys .black-keys-half .black-key:active {
  background-color: #555;
}
.piano-app .keyboard .octave .key {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.piano-app .keyboard .octave .key .note {
  font-size: 0.5rem;
  margin-bottom: 0.5rem;
}
/*# sourceMappingURL=styles.css.map */