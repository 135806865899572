h1 {
  text-align: center;
}

// 中央揃えされ、線を入れたh2コンポーネント
h2.center {
  text-align: center;
  position: relative;
  padding: 0;

  &::before {
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    width: 100%;
    height: 2px;
    content: "";
    background: $first-color;
  }

  span {
    position: relative;
    padding: 0 1em;
    background: #fff;
  }
}
