* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: unset;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
